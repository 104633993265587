










import { Component, Vue } from 'vue-property-decorator';

import { BASE_BLACK_COLOR } from '@/utils/constants';

@Component
export default class Loader extends Vue {
  color = BASE_BLACK_COLOR;
}
