<template>
  <Bar
    :chart-options="options"
    :chart-data="chart"
    chart-id="profile-details"
    :plugins="[]"
  />
</template>

<script>
import { Bar } from 'vue-chartjs/legacy';
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineController,
  LineElement,
  Filler,
} from 'chart.js';

import {
  CHART_OPTIONS,
  SERIES_BACKGROUND_COLOR,
  SUFFIXES,
} from '@/utils/constants';

ChartJS.register(
  Filler,
  LineController,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement
);

export default {
  name: 'BarChart',
  components: { Bar },
  props: {
    data: Object,
  },
  data() {
    return {
      chart: { labels: [], datasets: [] },
    };
  },
  computed: {
    options() {
      return {
        ...CHART_OPTIONS,
        plugins: {
          tooltip: {
            enabled: false,
          },
          legend: {
            display: false,
          },
        },
      };
    },
    chartData() {
      return this.data;
    },
  },
  methods: {
    loadChart() {
      if (!!Object.keys(this.chartData).length) {
        this.chart.labels = [];
        this.chart.datasets = [];
        const [[key, value]] = Object.entries(this.chartData);
        for (let i = 0; i < value.length; i++) {
          this.chart.labels.push(value[i].jahr);
        }
        const dataRow = {
          data: [],
          backgroundColor: [],
          borderWidth: 1,
        };
        this.chart.labels.forEach((item, index) => {
          dataRow.backgroundColor.push(SERIES_BACKGROUND_COLOR[index]);
        });
        const suffix = SUFFIXES[key] ? SUFFIXES[key] : '';

        this.options.scales.y.ticks = {
          beginAtZero: true,
          padding: 10,
          callback: function (value) {
            return `${value.toLocaleString('de-DE', {
              minimumFractionDigits: 0,
            })} ${suffix}`;
          },
        };

        for (let i = 0; i < value.length; i++) {
          Object.keys(value[i]).forEach((key) => {
            if (key !== 'jahr') {
              if (value[i][key][0] === '>') {
                const str = value[i][key].slice(1, value[i][key].length);
                dataRow.data.push(str);
              }
              if (value[i][key][value[i][key].length - 1] === '+') {
                const str = value[i][key].slice(0, value[i][key].length - 1);
                dataRow.data.push(str);
              }
              dataRow.data.push(value[i][key]);
            }
          });
        }
        this.chart.datasets.push(dataRow);
      }
    },
  },
  mounted() {
    this.loadChart();
  },
};
</script>
