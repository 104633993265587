
































































































































import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { mapActions, mapGetters } from 'vuex';

import Error from '@/components/fmh_components/common/Error/Error.vue';
import Loader from '@/components/fmh_components/common/Loader/Loader.vue';
import ChildHeader from '@/components/fmh_components/common/ChildHeader/ChildHeader.vue';
import BarChart from '@/views/BPBankDetails/BarChart/BarChart.vue';
import BankingProfileTable from '@/components/BankingProfileTable/BankingProfileTable.vue';

import {
  BACKEND_URL,
  BASE_BLACK_COLOR,
  FIELDS_TRANSLATE,
} from '@/utils/constants';
import { exportXLS, fileFirstRowText, fileName } from '@/utils/helpers';

interface IEBankDetails {
  headers: object[] | undefined;
  rows: object[] | undefined;
  data: object[] | undefined;
  anbietername: string | undefined;
  id: string | undefined;
}
@Component({
  components: {
    Error,
    Loader,
    ChildHeader,
    BarChart,
    BankingProfileTable,
  },
  methods: {
    ...mapActions({
      fetchFullBProfile: 'bankingProfile/fetchFullBProfile',
    }),
  },
  computed: {
    ...mapGetters({
      loading: 'bankingProfile/loading',
      error: 'bankingProfile/error',
      bankDetails: 'bankingProfile/getBankDetails',
      isAuthorized: 'auth/isAuthorized',
    }),
  },
})
export default class BPBankDetails extends Vue {
  loading!: Boolean;
  error!: Boolean;
  isAuthorized!: Boolean;
  bankDetails!: Object;
  color = BASE_BLACK_COLOR;
  url: String = BACKEND_URL;
  [x: string]: any;

  fetchFullBProfile!: (payload) => Promise<Object>;

  @Watch('isAuthorized') fetchData(val: boolean) {
    if (val) {
      if (!Object.keys(this.bankDetails).length) {
        this.fetchFullBProfile(this.$router.currentRoute.params.id);
      }
    }
  }
  get contentMinHeight() {
    const height = window.innerHeight - 278;
    return {
      'min-height': `${height}px`,
    };
  }
  get headersItems() {
    return (this.bankDetails as IEBankDetails)?.headers || [];
  }
  get rowsItems() {
    return (this.bankDetails as IEBankDetails)?.rows || [];
  }
  async saveXLS() {
    const name = fileName((this.bankDetails as any)?.anbietername);
    const firstRowText = fileFirstRowText(new Date(), this.$date);
    exportXLS((this.$refs?.table as Vue)?.$el, name, firstRowText);
  }
  checkContent(data) {
    const [[, value]] = Object.entries(data);
    if (Array.isArray(value)) {
      if (typeof value[0] === 'object') {
        return 'arrayObjects';
      }
      return 'arrayStrings';
    }
    return 'string';
  }
  checkValue(data) {
    const [[key]] = Object.entries(data);
    return key === 'landkuerzel';
  }
  listContent(data) {
    const [[, value]] = Object.entries(data);
    return value;
  }
  title(data) {
    const [[key]] = Object.entries(data);
    return this.$t(`bankingProfile.table.headers.${FIELDS_TRANSLATE[key]}`);
  }
  previousPage() {
    this.$router.back();
  }
  created() {
    if (this.isAuthorized) {
      if (!Object.keys(this.bankDetails).length) {
        this.fetchFullBProfile(this.$router.currentRoute.params.id);
      }
    }
  }
  mounted() {
    (document as any).querySelector('.v-main__wrap').scrollTo(0, 0);
  }
}
